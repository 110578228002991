.feedback-faq-container {
display: flex;
width: 100%;
gap: 3rem;
}

.feedback-slider {
    position: relative;
    width: 100%;
    margin: auto;
    overflow: hidden;
  }
  
  .feedback-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: black;
    width: 38rem;
    padding: 1rem;
  }
  
  .feedback-image {
    flex-shrink: 0;
    margin-right: 1rem;
  }
  
  .feedback-image img {
    border-radius: 50%;
    width: 100px;
    height: 100px;
    object-fit: cover;
  }
  
  .feedback-content {
    flex-grow: 1;
    width: 25rem;
    margin-top: 1rem;
  }
  
  .feedback-button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: #007bff;
    color: #fff;
    border: none;
    padding: 0.5rem;
    cursor: pointer;
    border-radius: 50%;
    font-size: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .feedback-button.prev {
    left: 10px;
  }
  
  .feedback-button.next {
    right: 10px;
  }
  
  /* For tablets (landscape and portrait) */
@media (max-width: 1024px) {
  .feedback-faq-container {
    gap: 2rem;
  }

  .feedback-slider {
    width: 100%;
    margin: auto;
    overflow: hidden;
  }

  .feedback-card {
    width: 90%;
    max-width: 600px;
    padding: 2rem;
  }

  .feedback-image img {
    width: 80px;
    height: 80px;
  }

  .feedback-content {
    width: auto;
    margin-top: 0.5rem;
    margin-left: 2rem;
  }

  .feedback-button {
    width: 40px;
    height: 40px;
    font-size: 1.2rem;
  }

  .feedback-button.prev {
    left: 5px;
  }

  .feedback-button.next {
    right: 5px;
  }
}

/* For mobile devices (portrait and landscape) */
@media (max-width: 768px) {
  .feedback-faq-container {
    flex-direction: column;
    gap: 1rem;
  }

  .feedback-faq-section {
    padding: 1rem;
    height: fit-content;
}

  .feedback-slider {
    width: 100%;
    margin: auto;
    overflow: hidden;
  }

  .feedback-card {
    width: 100%;
    padding: 0.5rem;
    flex-direction: column;
    align-items: center;
  }

  .feedback-image img {
    width: 60px;
    height: 60px;
  }

  .feedback-content {
    width: 15rem;
    margin-top: 0.5rem;
    text-align: center;
  }

  .feedback-button {
    width: 35px;
    height: 35px;
    font-size: 1rem;
  }

  .feedback-button.prev {
    left: 5px;
  }

  .feedback-button.next {
    right: 5px;
  }
}

.error-message {
  color: red;
  margin-bottom: 10px;
  font-size: 14px;
}
 
.contact-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: url('../Image/formbg.png') no-repeat center center fixed;
    background-size: cover;
    padding: 20px;
    color: black;
    margin-top: 4rem;
  }
 
  .left-content {
    flex: 1;
    padding: 20px;
    text-align: left;
    color: rgb(0, 0, 0);
  }
 
  .form-content {
    background-color: #ffffff;
    border-radius: 15px;
    padding: 40px;
    max-width: 500px;
    width: 100%;
    box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);
    flex: 1;
  }
 
  .form-title {
    font-size: 28px;
    font-weight: bold;
    margin-bottom: 10px;
  }
 
  .form-subtitle {
    font-size: 16px;
    color: #000;
    margin-bottom: 30px;
    width: 25rem;
  }
 
  .contact-form {
    display: flex;
    flex-direction: column;
  }
 
  .form-input {
    padding: 15px;
    margin-bottom: 15px;
    border-radius: 5px;
    border: 1px solid #ccc;
    width: 100%;
    font-size: 14px;
  }
 
  .form-row {
    display: flex;
    gap: 10px;
  }
 
  
  .form-textarea {
    padding: 15px;
    margin-bottom: 20px;
    border-radius: 5px;
    border: 1px solid #ccc;
    font-size: 14px;
    height: 100px;
    resize: none;
  }
 
  .form-button {
    background-color: #e63946;
    color: white;
    padding: 15px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
  }
 
  .form-button:hover {
    background-color: #d62828;
  }
 
 
/* Media Queries for Responsiveness */
 
/* Tablet Devices */
@media (max-width: 1024px) {
  .contact-container {
    flex-direction: row;
    padding: 10px;
 
  }
 
  .left-content, .form-content {
    width: 100%;
    text-align: left;
  }
 
  .form-subtitle {
    width: 66%;
  }
 
  .form-row {
    flex-direction: column;
  }
 

}
 
/* Mobile Devices */
@media (max-width: 768px) {
  .contact-container {
    padding: 5px;
  }
 
  .form-content {
    padding: 20px;
    max-width: none;
    box-shadow: none; /* Remove shadow on smaller screens */
  }
 
  .form-title {
    font-size: 24px; /* Adjust font size for mobile */
  }
 
  .form-subtitle {
    font-size: 14px; /* Adjust font size for mobile */
  }
 
  .form-input, .form-textarea {
    font-size: 12px; /* Adjust font size for mobile */
  }
 
  .form-button {
    font-size: 14px; /* Adjust font size for mobile */
    padding: 10px;
  }
}
 
/* Base Styles for Hero Section */
.hero-section1 {
    position: relative;
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(225deg, rgba(255, 128, 255, 0.5), rgba(255, 255, 255, 0.5), rgba(219, 237, 224, 0.5));
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    z-index: 12;
    padding: 20px;
    box-sizing: border-box;
}
 
.hero-video1 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 0;
}
 
.hero-section1::before,
.hero-section1::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
}
 
.hero-section1::before {
    background: rgba(0, 0, 0, 0.5);
}
 
 
.side-lotti2{
    height: 350px;
    width:350px
}
/* .hero-section1::after {
    background: url('../Image/bg.jpg') no-repeat center center;
    background-size: cover;
    opacity: 0.3;
} */
 
/* Hero Content */
.hero-content1 {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    z-index: 3;
    width: 100%;
    max-width: 1200px;
    gap: 20px;
}
 
.main-content{
    display: flex;
    flex-direction:column ;
}
 
 
/* Tagline */
.tagline1 {
    text-align: left;
    color: #fff;
    width: 80%;
}
 
.tagline1 h1 {
    margin: 0;
}
 
.main-heading-vehicle {
    font-size: 2.5rem;
    font-weight: bold;
    line-height: 1.2;
}
 
.sub-heading-vehicle {
    font-size:1.5rem;
    font-weight: normal;
    margin-top: 0.5rem;
    color: #fff;
}
 
/* Button Container */
.vehicle-button-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: left;
    gap: 10px;
    margin-top: 1rem;
    width: 100%;
}
 
.vehicle-start-button,
.vehicle-stop-button {
    padding: 0.5rem 0.5rem;
   font-size: 16px;
    font-weight: bold;
    color: #fff;
    background-color: #007bff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;
    flex: 1 1 150px; /* Ensures buttons are flexible */
    max-width: 200px;
    z-index: 10;
}
 
/* Style for the container holding the upload button */
/* .upload-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    height: 10rem;
} */
 
/* Hide the default file input */
.upload-container input[type="file"] {
    display: none;
}
 
/* Style for the custom upload button */
.upload-button {
    /* display: inline-block; */
    padding: 1rem 1.4rem;
    /* font-size: 16px; */
    font-weight: bold;
    color: #fff;
    background: linear-gradient(90deg, #F86F03, #FFA41B);
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background 0.3s ease, transform 0.2s ease;
    text-align: center;
}
 
.video-preview-wrapper {
    position: relative;
    display: inline-block;
}
 
.remove-video-button {
    position: absolute;
    top: 70px;
    right: 10px;
    color: white;
    border: none;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
 
.remove-video-button:hover {
    background-color:rgb(202, 199, 199);
    transition: .3s;
}
 
 
/* Hover effect for the button */
.upload-button:hover {
    background: linear-gradient(90deg, #FFA41B, #F86F03);
    transform: scale(1.05);
}
 
/* Focus effect for the button */
.upload-button:focus {
    outline: none;
}
 
 
.vehicle-start-button:hover,
.vehicle-stop-button:hover {
    background-color: #0056b3;
    transform: scale(1.05);
   
}
 
.vehicle-start-button:focus,
.vehicle-stop-button:focus {
    outline: none;
    box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.5);
}
 
/* Webcam Display */
.webcam-container {
    width: 100%;
    max-width: 640px;
    aspect-ratio: 4 / 3;
    position: relative;
    border: 5px solid transparent;
    border-radius: 5px;
    overflow: hidden;
    background-color: rgba(255, 255, 255, 0.4);
}
 
.videoname{
    background-color: white;
    padding: 0.5rem 1rem;
    border-radius: 8px;
    text-align: center;
}
 
.webcam-container img,
.webcam-container video {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
 
.results-table-container{
    margin-right: 3rem;
}
 
.modal-content1 {
    background-color: #fff;
    padding: 1.8rem;
    border-radius: 10px;
    /* max-width: 500px; */
    /* width: 100%; */
    width: 800px;
    height: auto;
    text-align: center;
    /* position: relative; */
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}
 
.lottifile{
    /* background-color:plum ; */
    height: 280px;
    width: 280px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 1rem;
}
 
 
.results-scrollable {
    max-height: 300px; /* Limit the height of the scrollable area */
    overflow-y: auto;  /* Add vertical scrolling if content exceeds max height */
}
 
 
.modal-close-icon1 {
 
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-size: 1.2rem;
    transition: transform 0.3s ease;
}
 
.modal-close-icon1:hover {
    transform: scale(1.2);
}
 
.number-plate-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 4rem;
    padding: 1.4rem;
 
}
 
.number-plate-table th,
.number-plate-table td {
    padding: 10px;
    border: 1px solid #ccc;
}
 
.number-plate-table th {
    background-color: #f4f4f4;
    font-weight: bold;
}
 
.number-plate-table td {
    background-color: #fafafa;
}
 
.overlay-loader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); /* Semi-transparent dark background */
    z-index: 999; /* Ensure it covers other content */
    display: flex;
    align-items: center;
    justify-content: center;
}
 
/* Responsive Styles */
 
/* Mobile View */
@media (max-width: 768px) {
    .hero-section1 {
        padding: 10px;
        flex-direction: column;
    }
 
    .hero-content1 {
        flex-direction: column;
        align-items: center;
        gap: 10px;
    }
 
    .tagline1 {
        width: 100%;
        text-align: center;
    }
 
    .main-heading-vehicle {
        font-size: 2rem;
    }
 
    .sub-heading-vehicle {
        font-size: 1.2rem;
    }
 
    .vehicle-button-container {
        flex-direction: row;
        align-items: center;
        gap: 10px;
    }
 
    .vehicle-start-button,
    .vehicle-stop-button {
        flex: none;
        max-width: 100%;
    }
 
    .upload-button {
        padding: 0.8rem 1.2rem;
    }
 
    .webcam-container {
        max-width: 100%;
        aspect-ratio: auto;
    }
 
    .results-table-container {
        margin-right: 0;
    }
 
    .side-lotti2 {
        display: none;
    }
 
    .modal-content1 {
        width: 90%;
    }
}
 
/* Tablet View */
@media (min-width: 769px) and (max-width: 1024px) {
    .hero-section1 {
        padding: 15px;
        flex-direction: column;
    }
 
    .hero-content1 {
        flex-direction: row;
        align-items: center;
    }
 
    .tagline1 {
        width: 90%;
    }
 
    .main-heading-vehicle {
        font-size: 2.2rem;
    }
 
    .sub-heading-vehicle {
        font-size: 1.4rem;
    }
 
    .vehicle-button-container {
        justify-content: center;
    }
 
    .vehicle-start-button,
    .vehicle-stop-button {
        flex: 1 1 200px;
        max-width: 150px;
    }
}
 
.custom-footer {
    background: #343a40; /* dark background */
    text-align: left;
  }
  
  .footer-title {
    font-weight: bold;
  }
  
  .footer-text {
    color: #e9ecef; /* light text color */
  }
  
  .footer-subtitle {
    font-size: 1.25rem; /* slightly larger font size for subtitles */
  }
  
  .footer-list {
    margin: 0;
    padding: 0;
  }
  
  .footer-list-item {
    padding-bottom: 0.5rem; /* spacing between list items */
  }
  
  .social-media {
    display: flex;
    gap: 1rem; /* space between icons */
  }
  
  .social-icon {
    color: #e9ecef; /* light icon color */
    font-size: 1.5rem; /* icon size */
  }
  
  .footer-copy {
    margin: 0;
    font-size: 0.875rem; /* smaller font size for copyright */
  }
  
  .footer-links a {
    color: #e9ecef;
  }
  
  .footer-links a:hover {
    color: #ff5e14; /* hover color for links */
  }
  
  .custom-footer .footer-list-item {
    cursor: pointer;
    transition: text-decoration 0.2s ease-in-out;
  }
  
  .custom-footer .footer-list-item:hover {
    text-decoration: underline;
  }


.custom-footer a {
  color: inherit;
  text-decoration: none;
  transition: text-decoration 0.2s ease-in-out;
}

.custom-footer a:hover {
  text-decoration: underline;
}


/* Media Queries for Responsiveness */

/* Tablet Devices */
@media (max-width: 1024px) {
  .custom-footer {
    padding: 15px; /* Adjust padding for tablets */
  }

  .footer-title {
    font-size: 1.25rem; /* Adjust title size for tablets */
  }

  .footer-subtitle {
    font-size: 1.125rem; /* Adjust subtitle size for tablets */
  }

  .footer-text {
    font-size: 0.875rem; /* Adjust text size for tablets */
  }

  .social-media {
    gap: 0.5rem; /* Reduce gap between icons */
  }

  .social-icon {
    font-size: 1.25rem; /* Adjust icon size for tablets */
  }
}

/* Mobile Devices */
@media (max-width: 768px) {
  .custom-footer {
    padding: 10px; /* Adjust padding for mobile */
  }

  .footer-title {
    font-size: 1.125rem; /* Adjust title size for mobile */
  }

  .footer-subtitle {
    font-size: 1rem; /* Adjust subtitle size for mobile */
  }

  .footer-text {
    font-size: 0.75rem; /* Adjust text size for mobile */
  }

  .social-media {
    flex-direction: column; /* Stack icons vertically on mobile */
    gap: 0.5rem; /* Reduce gap between icons */
  }

  .social-icon {
    font-size: 1rem; /* Adjust icon size for mobile */
  }

  .footer-copy {
    font-size: 0.75rem; /* Adjust copyright text size for mobile */
  }
}
/* Sidebar.css */
.darksoul-sidenav {
  width: 23rem;
  height: 111.1vh !important;
  background-color: rgb(219, 237, 224);
  border: 1px solid rgb(255, 255, 255);
  backdrop-filter: blur(20px);
  display: flex;
  flex-direction: column;
  transition: width 0.9s, opacity 0.9s;
  cursor: pointer;
  zoom: 0.9;
  box-sizing: border-box; /* Ensure padding and border are included in height calculation */
  overflow-x: hidden;
}
 
.darksoul-sidenav.collapsed {
  width: 86px;
  flex-direction: column;
  overflow: hidden;
}
 
.darksoul-sidenav::-webkit-scrollbar {
  display: none; /* Hide scrollbar for webkit browsers like Chrome */
 
}
 
.darksoul-sidenav {
  -ms-overflow-style: none;  /* Hide scrollbar for Internet Explorer and Edge */
  scrollbar-width: none;  /* Hide scrollbar for Firefox */
}
 
.darksoul-sidenav .header {
  width: 100%;
  height: 20%;
  border-radius: 20px;
  display: flex;
  overflow: hidden;
}
 
.darksoul-sidenav .header .profile {
    margin: auto;
    margin-left: 29px;
    margin-right: -28px;
    width: 72%;
    height: 60%;
    display: flex;
    transition: opacity 0.9s;
}
 
.darksoul-sidenav .header .profile .profile-pic {
  margin: auto;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: white;
  display: flex;
}
 
.darksoul-sidenav .header .profile .profile-pic img {
  width: 30px;
  height: 30px;
  margin: auto;
  border-radius: 50%;
}
 
.darksoul-sidenav .header .profile .profile-name {
  margin: auto;
  margin-left: 0px;
  width: 60%;
  height: 50%;
  display: flex;
  flex-direction: column;
  transition: opacity 0.9s;
}
 
.darksoul-sidenav .header .profile .profile-name span {
  margin: auto;
  margin-left: 0px;
  margin-bottom: 0px;
  font-size: 1.1rem;
}
 
.darksoul-sidenav .header .profile .profile-name p {
  margin: auto;
  margin-left: 0px;
  margin-top: 0px;
  font-size: small;
  font-weight: 600;
}
 
.darksoul-sidenav .header .btn {
  margin: auto;
  width: 30%;
  height: 60%;
  display: flex;
}
 
 
.darksoul-sidenav .header .btn .circle {
  margin: auto;
  margin-right: -15px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: linear-gradient(90deg, white 50%, rgba(255, 255, 255, 0) 50%);
  display: flex;
  cursor: pointer;
}
 
.darksoul-sidenav .header .btn .circle img {
  margin: auto;
  margin-left: 5px;
}
 
.side-new{
  padding: 0px;
  margin: 0px;
  height: 10px;
  width: 10px;
}
 
 
.darksoul-sidenav .menus {
  width: 100%;
  height: 60%;
  background-color: rgba(255, 255, 255, 0);
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  cursor: pointer;
}
 
.darksoul-sidenav .menus p {
  width: 70%;
  height: 20px;
  margin-left: 6px;
  font-size: medium;
  transition: opacity 0.9s;
}
 
.darksoul-sidenav .menus .menu-container {
  width: 75%;
  height: 60%;
  display: flex;
  flex-direction: column;
  margin: auto;
  margin-top: 0px;
  cursor: pointer;
}
 
.darksoul-sidenav .menus .menu-container .menu {
  width: 100%;
  height: 50px;
  margin: auto;
  display: flex;
  border-radius: 5px;
  text-decoration: none; /* Remove underline */
  font-size: 1rem; /* Increase font size */
  font-weight: bold; /* Make the text bold */
  cursor: pointer;
  color: black;
}
 
/* .darksoul-sidenav .menus .menu:hover {
  background-color: white;
  box-shadow: 5px 5px 5px white;
} */
 
.darksoul-sidenav .menus .menu-container .menu .menu-img {
  margin: 0rem 1rem 0rem 1rem;
  display: flex;
  height: 100%;
}
 
.darksoul-sidenav .menus .menu-container .menu .menu-name {
  display: flex;
  width: 80%;
  height: 50%;
  transition: opacity 0.9s;
}

.darksoul-sidenav .menus .menu-container .menu .menu-name p {
  font-size: 1rem; /* Adjust the font size here if needed */
  font-weight: bold; /* Bold text */
}

.darksoul-sidenav .menus .menu .menu-img img {
  margin: auto;
}
 
.darksoul-sidenav .menus .menu .menu-name p {
  height: fit-content;
  font-size: small;
  /* margin: auto; */
  margin-left: 0px;
}
 
.service {
  height: 30% !important;
}

.menu{
  padding: 0.5rem 0rem;
}

/* Sidebar.css */
.menu:hover {
  background-color: skyblue;
  transition: 0.3s;
  color: white; /* Optional: Change the text color to white when active */
  border-radius: 5px; /* Keep the border-radius for the active link */
}

.menu .active-link p {
  color: white; /* Ensure the text inside the active link is white */
}
 
.darksoul-sidenav .footer {
  width: 100%;
  height: 20%; /* Set height to ensure all sections fit within the 100vh */
  background-color: rgba(255, 255, 255, 0);
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
}
 
 
.darksoul-sidenav .footer .social-media {
  margin-bottom: 10px;
  display: flex;
  gap: 10px; /* Space between icons */
}
 
.darksoul-sidenav .footer .social-media a {
  color: black; /* Icon color */
  text-decoration: none; /* Remove underline from links */
}
 
.darksoul-sidenav .footer .social-media a:hover {
  color: #007bff; /* Change color on hover */
}
 
/* Adjust social media icons to column layout when collapsed */
.darksoul-sidenav.collapsed .footer .social-media {
  flex-direction: column; /* Stack icons vertically */
  gap: 5px; /* Adjust space between icons */
}
 
/* Hide text and adjust icon size when collapsed */
.darksoul-sidenav.collapsed .profile-name,
.darksoul-sidenav.collapsed .menus .menu-name,
.darksoul-sidenav.collapsed .create-task {
  opacity: 0;
  visibility: hidden;
}
 
.darksoul-sidenav.collapsed .profile-pic img,
.darksoul-sidenav.collapsed .menu-img img,
.darksoul-sidenav.collapsed .social-media a {
  width: 30px; /* Adjust icon size when collapsed */
  height: 30px; /* Adjust icon size when collapsed */
}
 
/* Media Query for Desktop View */
@media (min-width: 769px) {
  .hamburger-menu {
    display: none; /* Hide the hamburger menu on desktop */
  }
}
 
/* Media Query for Mobile View */
@media (max-width: 768px) {
  .btn {
    display: none;
  }
 
  .darksoul-sidenav {
    width: 0; /* Hide the sidebar initially */
    visibility: hidden; /* Hide the sidebar */
    overflow: hidden; /* Prevent overflow */
    transition: width 0.3s ease, visibility 0.3s ease;
  }
 
  .darksoul-sidenav.collapsed {
    width: 4rem;  /* Expand sidebar width when hamburger is clicked */
    visibility: visible; /* Make the sidebar visible */
  }
 
  .hamburger-menu {
    display: block;
    position: fixed;
    top: 6px;
    left: 8px;
    padding: 5px;
    border-radius: 2rem;
    border: 1px solid blue;
    background-color: white;
    z-index: 1000;
    cursor: pointer;
  }
 
  .close-menu {
    display: none; /* Hide the close button by default */
    position: fixed;
    top: 20px;
    right: 20px;
    z-index: 1001;
    cursor: pointer;
  }
 
  /* Show close icon when sidebar is expanded */
  .darksoul-sidenav.collapsed ~ .close-menu {
    display: block;
  }
 
  .profile-name,
  .menus .menu-name,
  .footer {
    opacity: 1; /* Show the text when expanded */
    visibility: visible;
  }
 
  /* Hide text when sidebar is collapsed */
  .darksoul-sidenav:not(.collapsed) .profile-name,
  .darksoul-sidenav:not(.collapsed) .menus .menu-name,
  .darksoul-sidenav:not(.collapsed) .footer {
    opacity: 0;
    visibility: hidden;
  }
}
 
.face-verification {
    display: flex;
    justify-content: space-between;
    padding: 20px;
    background: linear-gradient(to bottom right, #007bff, #000);
    min-height: 94.3vh;
}

.left-section, .right-section {
    flex: 1;
    padding: 0rem 1rem 0rem 1rem;
    background: white;
    border-radius: 10px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s, box-shadow 0.3s;
}

.left-section:hover, .right-section:hover {
    transform: translateY(-5px);
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.2);
}

.left-section {
    margin-right: 20px;
}

.face-verification h2 {
    font-size: 1.5em;
    font-weight: bold;
    color: #333;
    padding: 2rem 0rem 2rem 0rem;
    text-align: center;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
}

.webcam-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
}

.webcam {
    width: 100%;
    max-width: 400px;
    border-radius: 10px;
    margin-bottom: 30px;
    background-color: black;
    padding: 1rem;
}

.buttons {
    display: flex;
    gap: 10px;
}

.btnn{
    padding: 10px 20px;
    font-size: 1em;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background 0.3s ease, transform 0.2s;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
}

.btnn:hover {
    transform: translateY(-2px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

.upload-btn {
    margin-bottom: 2rem;
    margin-top: -2rem;
}

.capture-btn {
    background: #007bff;
}

.capture-btn:hover {
    background: #0056b3;
}

.clear-btn {
    background: #6c757d;
}

.clear-btn:hover {
    background: #5a6268;
}

.submit-btn {
    background: #28a745;
    margin-top: 10px;
}

.submit-btn:hover {
    background: #218838;
}

.upload-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: -2rem;
}

.upload-btn {
    display: flex;
    align-items: center;
    padding: 10px 20px;
    font-size: 1em;
    color: #fff;
    background: #ff9800;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background 0.3s ease, transform 0.2s;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
}

.upload-btn:hover {
    transform: translateY(-2px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

.upload-btn svg {
    margin-right: 10px;
}

.upload-container input[type="file"] {
    display: none;
}

.image-preview {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.image-preview img {
    max-width: 100%;
    border-radius: 10px;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
    margin-bottom: 10px;
}

.textpara {
    text-align: center;
}

.username-container input {
    padding: 5px;
    border-radius: 10px;
    color: black;
    text-align: center;
}

.username-container {
    justify-content: center;
    display: flex;
    text-align: center;
    align-items: center;
}

/* Tablet (between 600px and 1024px) */
@media (max-width: 1024px) and (min-width: 600px) {
    .face-verification {
        flex-direction: column;
    }

    .left-section, .right-section {
        margin-right: 0;
        margin-bottom: 20px;
        padding: 10px;
    }

    .textpara {
        text-align: center;
    }


    .buttons {
        flex-direction: row;
    }

    .face-verification h2 {
        font-size: 1.25em;
        padding: 1rem;
    }

    .upload-btn {
        width: 17%;
        margin-top: 1px;
    }
}

@media (max-width: 768px) {

    .darksoul-sidenav .header .profile {
        margin: 3rem 0rem 0rem 0rem;
        margin-left: 20px;
        margin-right: -10px;
        width: 72%;
        height: 60%;
        display: flex;
        transition: opacity 0.9s;
    }
    
    .face-verification {
        flex-direction: column;
        padding: 10px;
    }

    .left-section, .right-section {
        margin-right: 0;
        margin-bottom: 20px;
        padding: 1rem;
        box-shadow: none;
        transform: none;
    }

    .left-section:hover, .right-section:hover {
        transform: none;
        box-shadow: none;
    }

    .face-verification h2 {
        font-size: 1.2em;
        padding: 2rem;
    }

    .textpara {
        text-align: center;
    }

    .webcam {
        width: 100%;
        max-width: none;
    }

}
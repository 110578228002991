.hero-section2 {
    position: relative;
    min-height: 100vh;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    background: linear-gradient(225deg, rgba(255, 128, 255, 0.5), rgba(255, 255, 255, 0.5), rgba(219, 237, 224, 0.5));
    backdrop-filter: blur(10px);
    z-index: 12;
}

.hero-video2 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 0;
}

.hero-section2::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1;
}

.hero-content2 {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    max-width: 1200px;
    height: 100%;
    gap: 3rem; /* Gap between tagline and webcam */

    padding: 20px;
    z-index: 3;
}

.tagline2 {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 20px;
    margin-right: 3rem;
    text-align: left;
    color: #fff;
    transition: margin-left 0.3s ease;
}

.main-heading {
    font-size: 3rem;
    font-weight: bold;
    display: block;
    line-height: 1.2;
}

.sub-heading {
    font-size: 1.5rem;
    font-weight: bold;
    display: block;
    margin-top: 0.5rem;
    color: #fff;
}

.weapon-button-container {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    cursor: pointer;
    margin-top: 1rem;
}

.weapon-verify-button,
.weapon-upload-button {
    padding: 10px 20px;
    font-size: 1.2rem;
    font-weight: bold;
    color: white;
    border: none;
    border-radius: 5px;
    transition: background-color 0.3s ease, transform 0.3s ease;
}

.weapon-verify-button {
    background-color: rgb(255, 51, 255);
}

.weapon-upload-button {
    background-color: #007bff;
}

.hero-image2{
    max-width: 100%;  /* Ensure it does not overflow the parent */
    max-height: 100%; /* Ensure proper height scaling */
}

.weapon-results {
    position: fixed;
    bottom: 25px;
    right: 17rem;
    width: 25%;
    background-color: white;
    padding: 0.6rem 1rem;
    z-index: 99999;
    color: black;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
}

@media (max-width: 768px) {
    .weapon-results {
        right: 10px;
        bottom: 10px;
        width: 90%;
    }
}

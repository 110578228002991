.home-container {
  position: relative;
  color: white;
  overflow-y: scroll !important;
  height: 1rem;
  min-height: 100%;
  text-align: center;
  overflow: hidden;
}
 
.hero-section::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgb(16 1 1 / 43%);
  z-index: 0;
}
 
.hero-section {
  display: flex;
  background: url('../Image/bg.jpg') no-repeat center center fixed;
  background-size: cover;
  justify-content: space-between;
  align-items: center;
  position: relative;
  height: 36.6rem;
  z-index: 1;
}

.home-content-right{
  height:25rem;
  width: 25rem;
}
 
.home-content-left, .home-content-right {
  flex: 1;
  text-align: left;
  z-index: 9999;
  margin-left: 5rem;
}
 
.meridian-logo {
  max-width: 150px;
}
 
.home-content-left h1 {
  font-size: 2.5rem;
  margin-bottom: 0rem;
  margin-top: 1rem;
  font-weight: bolder;
  letter-spacing: 0.1rem;
}
 
.home-content-left p {
  font-size: 1.2rem;
  margin-bottom: 2rem;
  margin-top: 1rem;
}
 
.button-group {
  display: flex;
  gap: 10px;
}
 
.primary-button, .secondary-button {
  background-color: #00aaff;
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}
 
.primary-button:hover {
  background-color: #0088cc;
}
 
.secondary-button {
  background-color: #ffaa00;
}
 
.secondary-button:hover {
  background-color: #cc8800;
}
.logos {
  padding: 12px 0;
  white-space: nowrap;
  width: 70.5rem;
}
 
.logos:before,
.logos:after {
  position: absolute;
  top: 0;
  content: "";
  width: 250px;
  height: 100%;
  z-index: 2;
}
 
.logos:before {
  left: 0;
}
 
.logos:after {
  right: 0;
}
 
.logo_items {
  display: flex;
  animation: scroll 16s linear infinite;
}
 
.logos:hover .logo_items {
  animation-play-state: paused;
}
 
.logo_items img {
  height: 80px;
  padding: 1rem 2rem 0 2rem;
}
 
@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}
 
.verification-section {
  background-color: #f8f9fa;
  color: #333;
  padding: 70px 20px;
  text-align: center;
}
 
.verification-section h2 {
  margin-bottom: 50px;
  font-size: 3.4rem;
}
 
.verification-cards {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 15px;
}
 
.verification-card {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 20px;
  text-align: center;
  width: 250px;
}
 
.verification-card h3 {
  font-size: 1.1rem;
  margin-bottom: 10px;
}
 
.verification-card .highlight {
  display: block;
  background-color: #007bff;
  color: #fff;
  padding: 5px;
  border-radius: 5px;
  margin-bottom: 10px;
}
 
.verification-card p {
  font-size: 1.4rem;
  font-weight: bold;
}
 
.pdset {
padding: 0px !important;
}
 
.bgstepclr {
background-color: #377dffbf;
border-radius: 0rem 25rem 0rem 0rem;
padding: 0px !important;
}
 
.imagep img{
margin: 1rem 1rem 0rem 0rem;
padding: 0rem 2rem 0rem 2rem;
}
 
/* .bgsteponeclr {
  background-color: #000000b0;
  border-radius: 56rem 0rem 0rem 0rem;
}
 
.bgsteptwoclr {
  background-color: #000000b0;
  border-radius: 0rem 0rem 0rem 33rem;
} */
 
/* .mergingone {
  margin-bottom: -2.1px;
  }
 
  .mergingtwo {
  margin-top: -0.1rem;
  }
 
  .textset {
  margin-left: 1rem;
  }
   */
 
.new-block-wrapper {
  /* padding: 20px; */
  background-color: #f9f9f9; /* Example background color */
}
 
.new-block-heading {
  text-align: center;
  margin-bottom: 20px;
}
 
.new-block-heading h1 {
  font-size: 2.4rem;
  color: #007bff;
  margin: 0;
}
 
.new-block-heading h2 {
  font-size: 1.2rem;
  color: #6c757d;
  margin-top: 1rem;
}
 
.new-block {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0rem 2rem 0rem 2rem;
  background-color: #f9f9f9;
}
 
.block-margin {
margin-top: -7rem;
margin-bottom: -4rem;
}
 
.new-block h1 {
  margin-bottom: 0px;
  font-size: 2.5rem;
  color: #007bff;
}
 
.new-block-content {
  flex: 1 1;
  /* padding: 5rem 2.5rem 0rem 0rem; */
  color: black;
  text-align: left;
  /* height: 22.8rem; */
}
 
.new-block-content h1 {
  margin-bottom: 0px;
  font-size: 1.5rem;
  color: #007bff;
}
 
.new-block-content h2 {
  margin-top: 4px;
}
 
.new-block-image {
  flex: 1;
  padding: 0rem 2rem 0rem 2rem;
  text-align: right; /* Ensure the image is on the right */
}
 
.new-block-image img {
  max-width: 100%;
  height: auto;
  border-radius: 10rem;
}
 
.faq-column {
  margin-left: -10rem;
  width: 68rem;
  /* margin : auto; */
}
 
.feedback-faq-section {
  padding: 2rem;
}
 
.feedback-faq-section {
height: 63%;
flex-wrap: wrap;
display: flex;
/* background: rgb(16 1 1 / 35%); */
/* background: url('../Image/bg.jpg') no-repeat center center fixed;
background-size: cover; */
}
 
.student-feedback, .faq {
  padding: 1rem;
}
 
.student-feedback {
  border-right: 1px solid #ddd;
}
 
.faq {
  padding-left: 1rem;
}
 
.accordion-button:not(.collapsed) {
  color: #0d6efd;
  background-color: #e9ecef;
}
 
/* Container for the FAQ column */
.faq-column {
  padding: 2rem;
  background: rgb(0 0 0 / 16%); /* Semi-transparent white background */
  border: 1px solid rgba(255, 255, 255, 0.3); /* Semi-transparent border */
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow-y: scroll;
  height: 23rem;
}
 
.faq-column h2 {
  margin-bottom: 1.5rem;
  font-size: 1.5rem;
  font-weight: bold;
  color: #343a40;
}
 
/* FAQ items */
.faq-item {
  margin-bottom: 1rem;
}
 
/* FAQ buttons */
.faq-button {
  width: 100%;
  padding: 0.45rem;
  background-color: #007bff;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  font-weight: bold;
  text-align: left;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
}
 
.faq-button:hover {
  background-color: #0056b3;
  color: #e9ecef;
}
 
/* FAQ content */
.faq-content {
  padding: 1rem;
  background-color: #001aff;
  border: 1px solid #dee2e6;
  border-radius: 5px;
  margin-top: 0.5rem;
  text-align: left;
  display: none; /* Hidden by default */
}
 
.faq-content p {
  margin: 0;
  color: #495057;
}
 
/* Show the FAQ content when the button is clicked */
.faq-content.show {
  display: block;
}

.faq-content.active {
  display: block;
}
 
/* For tablets (landscape and portrait) */
@media (max-width: 1024px) and (min-width: 600px) {
  .logos {
    padding: 12px 0;
    white-space: nowrap;
    width: 50rem;
}
  .hero-section {
    flex-direction: column;
    height: 68rem;
    padding: 2rem 0;
    text-align: center;
  }
 
  .home-content-left, .home-content-right {
    margin-left: 0;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
 
  .home-content-left h1 {
    font-size: 2rem;
  }
 
  .home-content-left p {
    font-size: 1rem;
  }
 
  .meridian-logo {
    max-width: 120px;
  }
 
  .button-group {
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 10px;
    width: 30rem;
  }
 
  .new-block {
    flex-direction: column;
    text-align: center;
  }
 
  .new-block-content {
    margin-bottom: 2rem;
    text-align: center;
  }
 
  .new-block-image {
    text-align: center;
  }
 
  .bgstepclr {
    background-color: white;
  }
 
  .new-block-image img {
    max-width: 100%;
    height: 30rem;
    margin: 6rem 0rem 0rem 0rem;
    border-radius: 10rem;
}
 
  .logos img {
    height: 80px;
  }
 
  .verification-cards {
    flex-direction: column;
  }
 
  .verification-card {
    width: 100%;
  }
 
  .faq-column {
    padding: 1rem;
    width: 40rem;
  }
 
  .faq-button {
    font-size: 0.9rem;
  }
  .mobilesteppone {
    flex-direction: column-reverse;
    }
 
}
 
/* For mobile devices (portrait and landscape) */
@media (max-width: 768px) {
 
  .block-margin {
    margin-top: -1rem;
    margin-bottom: -1rem;
    }
 
  .primary-button {
  font-size: 14px;
}
 
  .home-container {
    padding: 0rem;
    text-align: left;
  }
 
  .logos {
    width: 23rem;
  }
 
  .hero-section {
    flex-direction: column-reverse;
    height: auto;
    text-align: left;
  }
 
  .home-content-left, .home-content-right {
    margin-left: 0;
    text-align: left;
    padding: 0rem 0rem 0rem 1rem;
  }
 
  .home-content-left h1 {
    font-size: 1.8rem;
  }
 
  .home-content-left p {
    font-size: 0.9rem;
  }
 
  .meridian-logo {
    max-width: 100px;
  }
 
  .button-group {
    flex-direction: row;
    gap: 8px;
    margin-bottom: 1rem;
    width: 21rem;
}
 
  .new-block {
    flex-direction: column;
    padding: 1rem ! IMPORTANT;
  }
 
  .new-block-content h2 {
    font-size: 23px;
}
 
  .new-block-content {
    margin-bottom: 0.1rem;
    text-align: left;
  }
 
  .new-block-image {
    padding: 0px;
}
  .new-block-image img {
    max-width: 100%;
    border-radius: 2rem;
    margin: 0;
    padding: 0;
    margin-bottom: 2rem;
  }
 
  .bgstepclr {
    background-color: #ffffffbf;
}
 
  .mobilesteppone {
  flex-direction: column-reverse;
  }
 
  .logos img {
    height: 60px;
  }
 
  .verification-section {
    padding: 20px 10px;
  }
 
  .verification-cards {
    flex-direction: column;
    gap: 10px;
  }
 
  .verification-card {
    width: 100%;
  }
 
  .verification-section h2 {
    margin-bottom: 30px;
    font-size: 1.3rem;
}
 
.new-block-heading h1 {
  font-size: 1.3rem;
  margin-top: 40px;
}
 
  .faq-column {
    padding: 1rem;
    height: auto;
    margin-bottom: 2rem;
 
  }
 
  .faq-button {
    font-size: 0.8rem;
  }
 
  .faq-content {
    font-size: 0.9rem;
  }
}
 
 
.spinner {
    position: fixed; /* Use fixed positioning to ensure it's visible */
    top: 50%; /* Center vertically */
    left: 50%; /* Center horizontally */
    transform: translate(-50%, -50%); /* Adjust for centering */
    width: 56px;
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999; /* Ensure it's above other content */
}
 
.spinner::before,
.spinner::after {
    border: 6.7px solid skyblue;
    border-radius: 50%;
    position: absolute;
    content: '';
    display: block;
}
 
.spinner::before {
    width: 33.6px;
    height: 33.6px;
    border-bottom-color: transparent;
    border-left-color: transparent;
    animation: spinner-1o3y8q 0.75s infinite linear reverse;
}
 
.spinner::after {
    animation: spinner-1o3y8q 0.5s infinite linear;
    height: 56px;
    width: 56px;
    border-right-color: transparent;
    border-top-color: transparent;
}
 
@keyframes spinner-1o3y8q {
    to {
        transform: rotate(360deg);
    }
}
 
 
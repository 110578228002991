.step-nav {
    display: flex;
    justify-content: center;
    background-color: #f8f9fa;
    padding: 10px;
    border-bottom: 1px solid #ddd;
  }
  
  .nav-btn {
    background: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    margin: 0 10px;
    cursor: pointer;
    width: 15rem;
    transition: background 0.3s ease;
  }
  
.nav-btn {
    position: relative;
    background: #e0e0e0; /* Light grey for inactive steps */
    color: #000;
    border: none;
    border-radius: 5px 0 0 5px; /* Rounded corners on the left side */
    padding: 10px 20px;
    margin: 0;
    cursor: pointer;
    transition: background 0.3s ease;
    opacity: 0.6;
    font-weight: normal;
}
 
.nav-btn:first-child {
    border-radius: 5px 0 0 5px; /* Rounded corners only on the first button */
}
 
.nav-btn:last-child {
    border-radius: 0 5px 5px 0; /* Rounded corners only on the last button */
}
 
.nav-btn.active {
    background: #4CAF50; /* Green color for active step */
    color: white;
    opacity: 1;
    font-weight: bold;
}
 
.nav-btn::after {
    content: '';
    position: absolute;
    top: 0px;
    right: -20.7px;
    width: 0;
    height: 0;
    border-top: 25px solid transparent;
    border-bottom: 20px solid transparent;
    border-left: 20px solid #e0e0e0;
    z-index: 1;
}
 
.nav-btn.active::after {
    border-left-color: #4CAF50; /* Arrow color matching active button */
}
 
.nav-btn:last-child::after {
    display: none; /* Remove the arrow from the last button */
}
  

.face-registration{
    display: flex;
    justify-content: space-between;
    padding: 20px;
    background: linear-gradient(to bottom right, #007bff, #000);
    min-height: 94.3vh;
  }
 
  .left-section, .right-section {
    flex: 1;
    padding: 0rem 1rem 0rem 1rem;
    background: white;
    border-radius: 10px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s, box-shadow 0.3s;
  }
 
  .left-section:hover, .right-section:hover {
    transform: translateY(-5px);
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.2);
  }
 
  .left-section {
    margin-right: 20px;
  }
 
 .face-registration h2 {
    font-size: 1.5em;
    font-weight: bold;
    color: #333;
    padding: 2rem 0rem 2rem 0rem;
    text-align: center;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
  }
 
  .webcam-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
  }
 
  .webcam {
    width: 100%;
    max-width: 400px;
    border-radius: 10px;
    margin-bottom: 30px;
    background-color: black;
    padding: 1rem;
  }
 
  .buttons {
    display: flex;
    gap: 10px;
  }


 
  .btnn {
    padding: 10px 20px;
    font-size: 1em;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background 0.3s ease, transform 0.2s;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  }
 
  .btnn:hover {
    transform: translateY(-2px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  }

  .upload-btn {
    margin-bottom: 2rem;
    margin-top: -2rem;
  }
 
  .capture-btn {
    background: #007bff;
  }
 
  .capture-btn:hover {
    background: #0056b3;
  }
 
  .clear-btn {
    background: #6c757d;
  }
 
  .clear-btn:hover {
    background: #5a6268;
  }
 
  .submit-btn {
    background: #28a745;
    margin-top: 10px;
  }
 
  .submit-btn:hover {
    background: #218838;
  }
 
  .upload-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: -2rem;
  }
 
  .upload-btn {
    display: flex;
    align-items: center;
    padding: 10px 20px;
    font-size: 1em;
    color: #fff;
    background: #ff9800;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background 0.3s ease, transform 0.2s;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  }
 
  .upload-btn:hover {
    transform: translateY(-2px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  }
 
  .upload-btn svg {
    margin-right: 10px;
  }
 
  .upload-container input[type="file"] {
    display: none;
  }
 
  .image-preview {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
 
  .image-preview img {
    max-width: 100%;
    border-radius: 10px;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
    margin-bottom: 10px;
  }

  .textpara {
    text-align: center;
}

.username-container input {
padding: 5px;
border-radius: 10px;
color: black;
text-align: center;
}
   
.username-container {
justify-content: center;
display: flex;
text-align: center;
align-items: center;
} 

/* Tablet (between 600px and 1024px) */
@media (max-width: 1024px) and (min-width: 600px) {
    .face-registration{
        flex-direction: column;
    }

    .overflow {
      overflow-y: scroll !important;
      height: 47rem !important;
    }

    .left-section, .right-section {
        margin-right: 0;
        margin-bottom: 20px;
        padding: 10px;
    }
    .textpara {
        text-align: center;
    }

    .buttons {
        flex-direction: row;
    }

    .face-registration h2 {
        font-size: 1.25em;
        padding: 1rem;
    }

    .upload-btn{
      width: 17%;
      margin-top: 1px;
    }
}

  @media (max-width: 768px) {

    .step-nav {
      flex-direction: column;
      align-items: center;
    }
  
    .step-nav .nav-btn {
      width: 100%;
      border-bottom: 1px solid #ddd; /* Add a border between buttons */
    }
  
    .step-nav .nav-btn:last-child {
      border-bottom: none; /* Remove the bottom border for the last button */
    }

  .nav-btn::after {
 display: none;
  }

    .face-registration {
      flex-direction: column;
      padding: 10px;
    }
  
    .left-section, .right-section {
      margin-right: 0;
      margin-bottom: 20px;
      padding: 1rem;
      box-shadow: none;
      transform: none;
    }
  
    .left-section:hover, .right-section:hover {
      transform: none;
      box-shadow: none;
    }
  
    .face-registration h2 {
      font-size: 1.2em;
      padding: 2rem;
    }

    .textpara {
        text-align: center;
    }

  
    .webcam {
      width: 100%;
      max-width: none;
    }
  
    .buttons {
        flex-direction: row;
        gap: 10px;
    }
  
    .btnn {
      width: 100%;
      padding: 10px;
      font-size: 1em;
    }
  
    .upload-btn {
      width: 56%;
    }
    .overflow {
      overflow-y: scroll !important;
      height: 43rem !important;
    }
  
    .image-preview img {
      max-width: 100%;
      margin-bottom: 10px;
    }
  }
  
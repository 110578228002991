/* Container for the entire page */
.tech-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background: #0d0d0d;
    color: #ffffff;
    position: relative;
    overflow: hidden;
  }
  
  /* Overlay to add a technical feel with subtle grid lines */
  .tech-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: radial-gradient(circle, rgba(0, 0, 0, 0.3) 1px, transparent 1px),
                radial-gradient(circle, rgba(0, 0, 0, 0.3) 1px, transparent 1px);
    background-size: 20px 20px;
    z-index: 1;
    opacity: 0.2;
  }
  
  /* Main content container */
  .tech-content {
    position: relative;
    z-index: 2;
    text-align: center;
    background: rgba(255, 255, 255, 0.05);
    padding: 50px;
    border-radius: 12px;
    box-shadow: 0 0 50px rgba(0, 255, 255, 0.2), 0 0 100px rgba(0, 255, 255, 0.1);
  }
  
  /* Main heading for Coming Soon */
  .tech-content h1 {
    font-size: 4rem;
    font-family: 'Courier New', Courier, monospace;
    margin: 0 0 20px;
    color:  #007bff;
    text-transform: uppercase;
    letter-spacing: 5px;
  }
  
  /* Subheading or additional text */
  .tech-content p {
    font-size: 1.2rem;
    color: #cccccc;
    font-family: 'Courier New', Courier, monospace;
    margin: 0;
  }
  
  /* Animation effect for added tech look */
  .tech-container::before {
    content: "";
    position: absolute;
    top: -50%;
    left: -50%;
    width: 200%;
    height: 200%;
    background: linear-gradient(90deg, transparent,  rgb(0, 123, 255, 0.2), transparent);
    animation: scan 5s infinite linear;
    z-index: 1;
  }
  
  /* Keyframes for scanline animation */
  @keyframes scan {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  
  /* Responsive design for mobile screens */
  @media (max-width: 768px) {
    .tech-content {
      padding: 30px;
    }
  
    .tech-content h1 {
      font-size: 3rem;
    }
  
    .tech-content p {
      font-size: 1rem;
    }
  }
  
.ui-benefits-section {
  background: none; /* Remove background image */
  background-color: #f4f4f4; /* Set a different background color if needed */
  padding: 20px; /* Adjust padding if necessary */
}

.benefits-container {
  background-color: #fff;
  margin: 0;
}

.benefits-title img {
  margin-top: -60px;
  max-width: 52%;
  margin-left: -18rem;
  height: 40rem;
  position: absolute;
}

.benefits-title {
  color: #fff;
  padding: 35px;
  width: 30rem;
  border-radius: 15px;
}

.benefits-title h2 {
  font-size: 2.5rem;
  margin: 0;
  font-weight: bold;
  text-align: left;
}

.benefits-list {
  padding: 20px;
}

.benefit-item {
  display: flex;
  align-items: center;
  background-color: #f8f9fa; /* Light background for the benefit items */
  border-radius: 15px;
  margin-bottom: 20px;
  padding: 20px;
  box-shadow: inset -6px 6px 13px 4px rgba(0, 0, 0, 0.1);
}

.benefit-number {
  font-size: 3.5rem;
  font-weight: bold;
  color: #000;
  margin-right: 40px;
}

.benefit-text h5 {
  font-size: 1.6rem;
  margin-bottom: 10px;
  color: cornflowerblue;
  text-align: left;
  font-weight: bold;
}

.benefit-text p {
  font-size: 1rem;
  margin: 0;
  color: black;
  text-align: left;
}

.bgcolorleft {
  background-color: #377dffbf;
  border-radius: 0rem 0rem 35rem 0rem;
}

.bgcolorright {
  background-color: #000000b0;
  border-radius: 40rem 40rem 0rem 40rem;
}

@media (min-width: 1200px) {
  .container, .container-lg, .container-md, .container-sm, .container-xl {
    max-width: 1425px;
  }
}

/* Tablet Devices (Portrait and Landscape) */
@media (max-width: 1024px) {
  .ui-benefits-section {
    padding: 15px; /* Adjusted padding for tablets */
  }

  .benefits-container {
    margin: 0 10px; /* Add some margin for tablets */
  }

  .benefits-title {
    padding: 20px; /* Reduced padding for tablets */
    width: 100%; /* Full width for smaller screens */
    border-radius: 10px;
  }

  .benefits-title img {
    margin-top: 5px;
    margin-left: 0rem;
    height: auto;
    position: relative;
  }

  .benefits-title h2 {
    font-size: 2rem; /* Reduced font size for tablets */
    text-align: center; /* Center the title text */
    color: black;
  }

  .benefits-container .row {
    flex-direction: column;
    width: 100%;
  }

  .benefits-container .col-lg-6 {
    width: 100%; /* Full width for each column */
  }

  .benefits-container .benefits-title,
  .benefits-container .benefits-list {
    width: 100%; /* Ensure inner content also takes full width */
  }

  .benefits-list {
    padding: 10px; /* Reduced padding for tablets */
  }

  .benefit-item {
    flex-direction: column;
    align-items: center;
    padding: 15px;
    margin-bottom: 15px;
  }

  .benefit-number {
    font-size: 2.5rem; /* Reduced font size for tablets */
    margin-right: 0; /* Remove right margin */
    margin-bottom: 10px; /* Add bottom margin */
  }

  .benefit-text h5 {
    font-size: 1.4rem; /* Slightly reduced font size for tablets */
    text-align: center; /* Center the text */
  }

  .benefit-text p {
    font-size: 0.9rem; /* Slightly reduced font size for tablets */
    text-align: center; /* Center the text */
  }

  .bgcolorright {
    border-radius: 0rem 0rem 40rem 40rem;
  }

  .bgcolorleft {
  border-radius: 40rem 40rem 0rem 0rem;
}
}


/* Mobile Devices (Portrait and Landscape) */
@media (max-width: 768px) {
  .ui-benefits-section {
    padding: 10px; /* Adjusted padding for mobile devices */
  }

  .benefits-container {
    margin: 0 -2px; /* Add some margin for mobile devices */
  }

  .benefits-title {
    display: flex;
    padding: 15px;
    width: 100%;
    flex-direction: column-reverse;
    border-radius: 5px;
  }

  .benefits-title img {
    margin-top: 0px;
    max-width: 100%;
    margin-left: 0rem;
  }

  .bgcolorleft {
    border-radius: 24rem 24rem 0rem 0rem !important;
  }
  
  .bgcolorright {
    border-radius: 0rem 0rem 24rem 24rem !important;
  }

  .benefits-title h2 {
    font-size: 1.3rem;
    text-align: center;
  }

  .benefits-list {
    padding: 5px; /* Reduced padding for mobile devices */
  }

  .benefit-item {
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
  }

  .benefit-number {
    font-size: 2rem; /* Further reduced font size for mobile devices */
    margin-right: 0; /* Remove right margin */
    margin-bottom: 5px; /* Add bottom margin */
  }

  .benefit-text h5 {
    font-size: 1.2rem; /* Further reduced font size for mobile devices */
    text-align: center; /* Center the text */
  }

  .benefit-text p {
    font-size: 0.8rem; /* Further reduced font size for mobile devices */
    text-align: center; /* Center the text */
  }

  .bgcolorleft, .bgcolorright {
    border-radius: 0; /* Remove border-radius for better fit on mobile devices */
  }
}

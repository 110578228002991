/* Styles for the Analytics component */
.analytics-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px;
  background: #ffffff;
  border: 1px solid black;
  border-radius: 10px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  max-width: 600px;
  margin: 20px auto;
}

.analytics-container h2 {
  margin-bottom: 15px;
  font-size: 1.75em;
  font-weight: bold;
  color: #333;
  text-align: center;
}

.confirmation-message {
  margin-bottom: 25px;
  font-size: 1.2em;
  color: #666;
  text-align: center;
}

.btn-analytics {
  padding: 10px 20px;
  font-size: 1rem;
  color: white;
  background-color: #007bff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.btn-analytics:hover {
  background-color: #0056b3;
}

.btn-analytics:focus {
  outline: none;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7); /* Darker overlay for better visibility */
  backdrop-filter: blur(5px);
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-content {
  position: relative;
  width: 100vw; /* Full viewport width */
  height: 100vh; /* Full viewport height */
  background: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-content iframe {
  width: 100%;
  height: 100%;
  border: none;
}

.close-modal {
  position: absolute;
  top: 20px;
  right: 20px;
  background-color: #FF4D4D; /* Red */
  border: none;
  color: white;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  cursor: pointer;
  border-radius: 5px;
  z-index: 1001; /* Ensure the button is above the iframe */
}









.analytics-container {
    text-align: center;
    padding: 20px;
  }
  
  .btn-analytics {
    margin-top: 10px;
  }
  
  .popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    z-index: 1000;
  }
  
  .popup-content {
    position: relative;
    width: 80%;
    height: 80%;
    background: white;
    border-radius: 10px;
    overflow: hidden;
    z-index: 1010; /* Ensure the popup content is above the overlay */
  }
  
  .popup-close {
    position: absolute;
    top: 10px;
    right: 10px;
    background: #f86f03;
    border: none;
    color: white;
    padding: 5px 10px;
    cursor: pointer;
    border-radius: 5px;
    z-index: 1020; /* Ensure the close button is above the iframe */
  }
  
  .popup-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    z-index: 1000; /* Ensure it's below the popup content */
  }